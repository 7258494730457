<cdk-accordion-item
    #accordionItem="cdkAccordionItem"
    class="flex flex-col bg-white rounded-md shadow dark:!shadow-none dark:!bg-dark-contrast"
    [attr.id]="accordionItem.id"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index()">
    <button
        (click)="itemClicked(accordionItem)"
        class="flex items-center w-full px-6 py-4 mb-0 space-x-6 leading-6 h5"
        role="button">
        <span class="text-left break-words grow">{{ title() }}</span>
        <span>
            <big-icon
                *transloco="let t; prefix: 'uiAccordion'"
                [icon]="accordionItem.expanded ? iconClose : iconPlus"
                [size]="'w-4'"
                class="my-auto fill-primary dark:!fill-dark-primary"
                [attr.aria-label]="accordionItem.expanded ? t('close') : t('open')" />
        </span>
    </button>
    <div
        role="region"
        class="block px-6 pb-2"
        [class]="[accordionItem.expanded ? 'block' : 'hidden', openForPrint() ? 'print:block' : '']"
        [attr.id]="'accordion-body-' + index()"
        [attr.aria-labelledby]="accordionItem.id">
        <ng-content ngProjectAs="[accordionItem]"></ng-content>
    </div>
</cdk-accordion-item>
